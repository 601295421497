<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <header
        v-if="!hideEnquiry"
        class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear lg:py-5"
        :class="
          fixtop
            ? ' bg-biege-1 shadow-md z-10'
            : ' bg-gradient-to-b from-biege-1'
        "
      >
        <div class="container">
          <div class="flex items-center justify-between text-gray-700">
            <!-- <h1 class="text-2xl font-bold lg:text-3xl">Floorlive</h1> -->
            <div>
              <img src="/images/main-logo.png" alt="" class="w-16 md:w-24 lg:w-20">
            </div>
            <div>
              <a href="tel:+60102193834">
                <i class="text-4xl md:text-5xl fa-solid lg:hidden fa-square-phone"></i>
                <p
                  class="hidden text-lg font-semibold leading-tight text-right lg:block"
                >
                  Call Us At: <br />
                  010-219 3834
                </p>
              </a>
            </div>
          </div>
        </div>
      </header>

      <!-- banner tagline -->
      <div class="py-40 md:py-48 lg:py-56">
        <div class="container">
          <div class="md:w-2/3">
            <div class="p-5 bg-black bg-opacity-50 rounded-md lg:py-10">
              <h1 class="text-3xl font-semibold leading-snug text-white md:leading-snug lg:leading-tight md:text-4xl lg:text-5xl">
                No.1 SPC <br class="hidden lg:block"> <span class="border-b-2 border-orange-1 text-orange-1"
                  > Flooring
                Specialist</span
                >
                in Malaysia
              </h1>
              <p
                class="mt-4 text-sm leading-tight text-white lg:mt-6 lg:text-lg md:text-base"
              >
                Over 15 years of experience in Flooring industry
              </p>

              <div v-if="!hideEnquiry" class="mt-6">
                <p
                  class="py-1 text-center text-gray-600 rounded-md bg-orange-1 w-44"
                >
                  <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }">
                    Get A Free Quote
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
