<template>
  <div>
    <div class="py-10">
      <div class="container">
        <h1 class="text-3xl font-semibold text-center text-brown-1">
          Our <span class="text-orange-1">Testimonials</span>
        </h1>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="relative p-5 rounded-md bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 text-red-600 fill-current"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
                  />
                </svg>
                <div class="pt-3 pb-20">
                  <p class="h-40 text-sm text-gray-600">
                    {{ item.p }}
                  </p>
                </div>
                <!-- <div class="pt-4">
                  <p class="text-base font-semibold text-red-900">
                    {{ item.client }}
                  </p>
                  <div class="text-yellow-400">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div> -->
                <div class="absolute w-20 bottom-2 right-4">
                  <img src="/images/google-review.png" alt="" class="" />
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="hidden">
          <img src="/images/bg-testimonial.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: " I recently installed SPC flooring in my home and I couldn't be happier with the results.Thanks a lot to Ah Ming!",
          client: "",
        },
        {
          p: " I recently hired a professional SPC flooring service to install new floors in my home, and I am extremely satisfied with their work. From start to finish, the service was top-notch, and the results exceeded my expectations.",
          client: "",
        },
        {
          p: " The team was highly knowledgeable and guided me through the entire process. They helped me choose the right SPC flooring option based on my preferences and needs. Their expertise was evident as they provided valuable advice on color schemes, textures, and patterns that would complement my home decor.",
          client: "",
        },
        {
          p: "The installation process was seamless and efficient. The team arrived on time, well-equipped, and ready to tackle the job.",
          client: "",
        },
        {
          p: " I was impressed by the professionalism and workmanship of Floorlive Enterprise. They were courteous, respectful, and diligent throughout the project. They completed the installation within the agreed-upon timeframe, without any delays or inconvenience",
          client: "",
        },
      ],
    };
  },
};
</script>
