var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"mt-6"},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplaySpeed":2500,"autoplayTimeout":6000,"responsive":{
            0: { items: 1, nav: false, dots: true },
            767: { items: 2, nav: false, dots: true },
            1000: { items: 3, nav: false, dots: true },
          }}},_vm._l((_vm.testimonials),function(item,i){return _c('div',{key:i,staticClass:"px-1"},[_c('div',{staticClass:"relative p-5 rounded-md bg-gray-50"},[_c('svg',{staticClass:"w-10 h-10 text-red-600 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"}})]),_c('div',{staticClass:"pt-3 pb-20"},[_c('p',{staticClass:"h-40 text-sm text-gray-600"},[_vm._v(" "+_vm._s(item.p)+" ")])]),_c('div',{staticClass:"absolute w-20 bottom-2 right-4"},[_c('img',{attrs:{"src":"/images/google-review.png","alt":""}})])])])}),0)],1),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-3xl font-semibold text-center text-brown-1"},[_vm._v(" Our "),_c('span',{staticClass:"text-orange-1"},[_vm._v("Testimonials")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden"},[_c('img',{attrs:{"src":"/images/bg-testimonial.jpg","alt":""}})])
}]

export { render, staticRenderFns }