<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- body section -->
    <section class="py-10">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="md:w-1/2">
            <h1 class="text-3xl font-semibold text-center md:text-4xl md:text-left text-brown-1">
              What <span class="text-orange-1">We Can Do</span>
            </h1>
            <p class="mt-6 text-sm text-center text-gray-600 md:text-justify">
              We take pride in our wide range of quality flooring solutions,
              made from the highest grade materials, combining technology and
              workmanship. As such, we are highly experienced with home, office
              and even building projects.
            </p>
          </div>
          <div class="mt-4 md:w-1/2 md:mt-0">
            <img src="/images/about-us.jpg" alt="" class="mx-auto lg:w-2/3" />
          </div>
        </div>
      </div>
    </section>

    <!-- why choose us -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <div class="md:w-2/3 lg:w-1/2">
          <div class="p-5 text-center md:text-left bg-brown-2 bg-opacity-80">
            <h1 class="text-2xl font-semibold text-white md:text-3xl">
              Why Choose Us
            </h1>
            <div class="bg-white w-12 h-0.5 mx-auto md:mx-0 my-3"></div>
            <p class="text-sm leading-tight text-white">
              In the world of modern flooring, SPC (Stone Plastic Composite)
              flooring has gained immense popularity for its durability,
              versatility, and aesthetic appeal. Floorlive Enterprise is a
              one-stop SPC flooring solution provider with a wide range of
              flooring solutions
            </p>
            <div class="mt-7 md:flex md:flex-wrap">
              <div v-for="(item, i) in choose_us" :key="i" class="w-2/3 pb-4 mx-auto md:w-1/2 md:p-2">
                <div
                  class="p-5 text-center text-white transition duration-200 ease-linear border-2 border-white hover:bg-orange-1 hover:border-orange-1">
                  <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
                  <div class="pt-3 text-sm">
                    <p class="leading-tight">{{ item.alt }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- product  -->
    <section class="py-10">
      <div class="container">
        <h1 class="text-3xl font-semibold text-center text-brown-1">
          Our <span class="text-orange-1">Products</span>
        </h1>
        <p class="mt-4 text-sm leading-tight text-center text-gray-600">
          As a discerning consumer, you understand the importance of finding a
          reliable and comprehensive solution when it comes to sourcing SPC
          flooring materials.We present to you the ultimate one-stop provider
          for all your SPC flooring needs. From product selection to
          installation, we will explore the myriad benefits and services offered
          by this exceptional company
        </p>

        <div class="pt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in product" :key="i" class="pb-10 md:w-1/2 lg:w-1/3 md:px-1">
            <div class="relative z-0">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" class="shadow-md" />
              <div class="absolute left-0 right-0 w-10/12 mx-auto -bottom-5">
                <p
                  class="py-2 text-sm font-semibold leading-tight text-center text-white transition duration-300 ease-linear lg:text-base bg-brown-2 hover:bg-orange-1">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- sub banner -->
    <section class="py-20 bg-center bg-no-repeat bg-cover md:py-28" style="background-image: url('/images/bg-sub.jpg')">
      <div class="container">
        <div class="text-white md:w-2/3">
          <p class="py-1 mb-3 text-lg text-center text-gray-900 w-60 bg-orange-1">
            Floorlive Enterprise
          </p>
          <h1 class="text-3xl font-bold md:text-4xl">
            Prioritizing <span class="text-orange-1">Workmanship</span> and
            Quality Products
          </h1>
          <p class="pt-5 text-sm lg:text-justify">
            Our high quality flooring is a worthwhile endeavor that can
            transform your space into a haven of elegance, durability, and
            functionality. The right flooring solution can enhance the aesthetic
            appeal of your rooms, contribute to better indoor air quality, and
            even increase your property value.
          </p>
        </div>
      </div>
    </section>

    <!-- gallery -->
    <section class="py-10">
      <div class="container">
        <h1 class="text-3xl font-semibold text-center text-brown-1">
          Our <span class="text-orange-1">Gallery</span>
        </h1>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 lg:w-1/4 md:w-1/3">
            <img :src="'/images/' + image + '.jpg'" alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md" />
          </div>
        </div>
      </div>
    </section>

    <Testimonials />

    <!-- footer -->
    <section id="enquiry" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-orange-1 bg-opacity-80">
            <div class="text-center">
              <p class="text-3xl font-bold text-white">Send an Enquiry</p>
              <div class="bg-gray-1 w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-700">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <!-- <h1
            class="py-2 mx-auto text-4xl font-bold text-center text-white bg-brown-2 w-60"
          >
            Floorlive
          </h1> -->
          <div>
            <img src="/images/main-logo.png" alt="" class="w-24 mx-auto">
          </div>
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-xl font-bold">Floorlive Enterprise</h1>
              <p class="text-base font-normal leading-tight md:text-xl">
                No.9, Jalan Damai Raya 1, 56000 Cheras, Selangor
              </p>
              <div class="flex justify-center my-2 space-x-1">
                <a href="https://maps.app.goo.gl/Xt37yrsnhBBBfRob6">
                  <img src="/images/google-maps.png" alt="" class="w-12">
                </a>
                <a href="https://www.waze.com/en/live-map/directions/my/wilayah-persekutuan-kuala-lumpur/kuala-lumpur/9,-jalan-damai-raya-1?place=EmI5LCBKYWxhbiBEYW1haSBSYXlhIDEsIERhbWFpIE5pYWdhLCA1NjAwMCBLdWFsYSBMdW1wdXIsIFdpbGF5YWggUGVyc2VrdXR1YW4gS3VhbGEgTHVtcHVyLCBNYWxheXNpYSIwEi4KFAoSCWXRmdkaNcwxEY4EDcz2axw2EAkqFAoSCa9EP9caNcwxEVxQ5HtSoi4-">
                  <img src="/images/waze.png" alt="" class="w-12">
                </a>
              </div>

              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  <a href="https://wa.me/60102193834" class="">
                    010-219 3834</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  Mobile : <a href="tel:+60102193834">010-219 3834</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import WaBtn from "@/components/WaBtn.vue";
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    WaBtn,
    Testimonials,
    EnquiryForm,
    MainBanner,
  },
  data() {
    return {
      fixtop: false,
      choose_us: [
        {
          image: "choose-1",
          alt: "Call Us For Free Quote",
        },
        {
          image: "choose-2",
          alt: "On Site Measurement",
        },
        {
          image: "choose-3",
          alt: "Concept Design Consultation",
        },
        {
          image: "choose-4",
          alt: "Process Order And Done",
        },
      ],
      product: [
        { image: "product-1", alt: "SPC Flooring" },
        { image: "product-2", alt: "Vinyl Flooring" },
        { image: "product-3", alt: "Laminate Flooring" },
        { image: "product-4", alt: "Decking Solutions" },
        { image: "product-5", alt: "Solid Wood Flooring" },
        { image: "product-6", alt: "Platform, Carpet, Self-Leveling" },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
        "gallery-9",
        "gallery-10",
        "gallery-11",
        "gallery-12",
      ],
    };
  },
};
</script>
